import type {MaterialDemandByDay, Schedule} from '../../../shared/interfaces/api'
import type {MockState} from '../../mockState'
import {mockState as mockState01} from '../01/index'
import schedule from '../02/schedule.json'

import materialDemandByDaySplitted from './materialDemandByDaySplitted.json'

// Key feature of sample dataset 02:
// 1) an empty schedule
// 2) a material demand data with a splitted forecast
export const mockState: MockState = {
  burglengenfeld: {
    ...mockState01.burglengenfeld,
    schedule: schedule as Schedule,
    materialDemandByDay: materialDemandByDaySplitted as MaterialDemandByDay
  }
}
