import queryString from 'query-string'
import {generatePath} from 'react-router'

import {appUrl, hproduce, HPRODUCE_TASKS} from '../hproduce/config'
import {TASKS_ASSIGNED_TO_ME_PARAM_KEY} from '../hproduce/consts'

export const getHProduceMyTasksUrl = () => {
  const stageSpecificHost = appUrl(hproduce.url)
  const path = generatePath(HPRODUCE_TASKS)
  const search = queryString.stringify({[TASKS_ASSIGNED_TO_ME_PARAM_KEY]: true})

  return `${stageSpecificHost}${path}?${search}`
}
