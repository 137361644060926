import type {ElectricityPrice, Latest} from '../../../shared/interfaces/api'
import {MockState} from '../../mockState'
import {mockState as mockState01} from '../01/index'

import electricityPrice from './electricityPrice.json'
import latestRaw from './latest.json'

// Key feature of sample dataset 03 is an existing actual prices for electricity forecast
export const mockState: MockState = {
  burglengenfeld: {
    ...mockState01.burglengenfeld,
    latest: latestRaw as Latest,
    electricityPrice: electricityPrice as ElectricityPrice
  }
}
